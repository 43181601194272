import '../styles/mensPageStyle.css'
import '../styles//photoCarouselStyle.css'
import AnimateOnScroll from './animate'
import PhotoDisplay from './carousel/photoDisplay'
import { useState } from 'react'
import { newShoes } from '../assets/newShoes'
import ModalGallery from './carousel/modal'

export default function NewArrivals() {

    const [modalOpen, setModalOpen] = useState(false)
    const [activeImage, setActiveImage] = useState('')

    function openModal(image) {
        setActiveImage(image)
        setModalOpen(true)
    }

    return (
        <section className="mensContainer">
           <AnimateOnScroll
             children={
                <div className='descriptionMensContainer'>
                    <h3> New Arrivals </h3>
                    <div>Check out our newest styles from your favorite brands. These and many more - arriving 
                        now for Spring and Summer 2024!
                    </div> 
                </div>
             }
             reappear={true}
             threshold={.3}
             right={true}
            />

            <PhotoDisplay images = {newShoes} openModal = {openModal} />
            
            <ModalGallery 
                modalOpen = {modalOpen} 
                setModalOpen = {setModalOpen} 
                activeImage = {activeImage} 
                setActiveImage = {setActiveImage}
                images = {newShoes}
            />
        </section>
    )
}