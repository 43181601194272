import '../styles/aboutuspageStyle.css'
import AnimateOnScroll from './animate'
import tabledisplay from '../images/storepics/table_display.jpg'
import dressdisplay from '../images/storepics/dress_display.jpg'
import walldisplay from '../images/storepics/wall_display.jpg'
import { useState } from 'react'

export default function AboutOtherwides () {

    const storeImages = [
        {name: 'Table Display',
        image: tabledisplay},
        {name: 'Dress Display',
        image: dressdisplay},
        {name: 'Wall Display',
        image: walldisplay},
    ]

    const [modalOpen, setModalOpen] = useState(false)
    const [activeImage, setActiveImage] = useState('')

    function openModal(image) {
        setActiveImage(image)
        setModalOpen(true)
    }

    function closeModal() {
        setModalOpen(false)
    }

    return (
        <div className="aboutStoreContainer">
            <div className='firstSection'>
            <AnimateOnScroll 
                children={<img className='shopImage' src= {storeImages[0].image} alt='store display' onClick={() => openModal(storeImages[0])}/>}
                reappear={true} 
                threshold={.4}
                right={false}
            />
            <AnimateOnScroll 
                children={
                    <div className='aboutTextContainer'> 
                        <h2>About Us</h2>
                        <div className='aboutTextDescription'>  
                        Otherwides is an independent, locally-owned shoe store that has been serving the Phoenix Metro area
                        for 20 years. We specialize in wide and extra-wide footwear for men and women, from dress, casual,
                        sandals, athletic, boots to athleisure we cover all the bases! We stock shoes from many well-known
                        quality brands and carry seasonal as well as core styles.
                        </div>
                    </div>
                }
                reappear={true}
                threshold={.3}
                right={true}
            />
            </div>
            <div className='secondSection'>
            <AnimateOnScroll 
                children={
                    <div className='aboutTextContainer'> 
                        <div className='aboutTextDescription'>  
                        
                        We are conveniently located near the US 60 and the 101 Freeway in Tempe. Otherwides has an
                        incredible assortment of in-stock wide shoes so you can forget about searching the big box stores for the
                        occasional wide width they might have, or the endless back and forth of online ordering. Come in and
                        let us measure your feet and bring you an assortment of shoes to try on!
                        Come shop local with us and experience the difference of shopping at a small local business dedicated
                        to customer service and the best selection of wide shoes in town!
                        </div>
                    </div>
                }
                reappear={true}
                threshold={.3}
                right={true}
            />
            <AnimateOnScroll 
                children={<img className='shopImage' src= {storeImages[1].image} alt='store display' onClick={() => openModal(storeImages[1])}/>}
                reappear={true} 
                threshold={.2}
                right={false}
            />
            </div>
            <AnimateOnScroll 
                children={<img className='shopImage' src= {storeImages[2].image} alt='second store display' onClick={() => openModal(storeImages[2])}/>}
                reappear={true} 
                threshold={.3}
                right={false}
            />
            <div className={modalOpen ? 'modalOpen' : 'modalClosed'}>
                <span className='closeIcon' onClick={closeModal}>&times;</span>
                <div className='modalContent'>
                    <img className='modalPic' src= {activeImage.image} alt={activeImage.name}></img>
                </div>
            </div>
        </div>
    )
}
