//import rave from '../images/newShoes/david_rave.jpg'
import anneka from '../images/newShoes/anneka.jpg'
import aspire from '../images/newShoes/aspire.jpg'
import benji from '../images/newShoes/benji.jpg'
import bigleague from '../images/newShoes/bigleague.jpg'
import bourbonst from '../images/newShoes/bourbonst.jpg'
import cabana from '../images/newShoes/cabana.jpg'
import camstrap from '../images/newShoes/camstrap.jpg'
import chloe from '../images/newShoes/chloe.jpg'
import denia from '../images/newShoes/denia.jpg'
import emerald from '../images/newShoes/emerald.jpg'
import emerson from '../images/newShoes/emerson.jpg'
import lakeside from '../images/newShoes/lakeside.jpg'
import lydia from '../images/newShoes/lydia.jpg'
import m880 from '../images/newShoes/m880.jpg'
import maryse from '../images/newShoes/maryse.jpg'
import maxine from '../images/newShoes/maxine.jpg'
import montana from '../images/newShoes/montana.jpg'
import park from '../images/newShoes/park.jpg'
import roshni from '../images/newShoes/roshni.jpg'
import serra from '../images/newShoes/serra.jpg'
import trio from '../images/newShoes/trio.jpg'
import Ushery from '../images/newShoes/Ushery.jpg'
import vibe from '../images/newShoes/vibe.jpg'
import w840 from '../images/newShoes/w840.jpg'
import willett from '../images/newShoes/willett.jpg'
import lightning from '../images/newShoes/lightning.jpg'
import fisherman from '../images/newShoes/fisherman.jpg'
import sparkle from '../images/newShoes/sparkle.jpg'
import romi from '../images/newShoes/romi.jpg'

const newShoes = [
    
    {name: 'Anneka',
    id: 0,
    image: anneka},

    {name: 'Aspire',
    id: 1,
    image: aspire},

    {name: 'Benji',
    id: 2,
    image: benji},
    
    {name: 'Big League',
    id: 3,
    image: bigleague},
    
    {name: 'Bourbon St. Oxford',
    id: 4,
    image: bourbonst},
    
    {name: 'Cabana',
    id: 5,
    image: cabana},
    
    {name: 'Cam Strap',
    id: 6,
    image: camstrap},
    
    {name: 'Chloe',
    id: 7,
    image: chloe},
    
    {name: 'Emerald',
    id: 8,
    image: emerald},
    
    {name: 'Denia',
    id: 9,
    image: denia},
    
    {name: 'Emerson',
    id: 10,
    image: emerson},
    
    {name: 'Lakeside Moc Oxford',
    id: 11,
    image: lakeside},
    
    {name: 'Lydia',
    id: 12,
    image: lydia},
    
    {name: 'M880',
    id: 13,
    image: m880},
    
    {name: 'Maryse',
    id: 14,
    image: maryse},
    
    {name: 'Maxine',
    id: 15,
    image: maxine},
    
    {name: 'Montana',
    id: 16,
    image: montana},

    {name: 'Park',
    id: 17,
    image: park},

    {name: 'Roshni',
    id: 18,
    image: roshni},

    {name: 'Serra',
    id: 19,
    image: serra},

    {name: 'Trio',
    id: 20,
    image: trio},

    {name: 'Ushery',
    id: 21,
    image: Ushery},
  
    {name: 'Vibe Knite Oxford',
    id: 22,
    image: vibe},

    {name: 'W840',
    id: 23,
    image: w840},
      
    {name: 'Willett',
    id: 24,
    image: willett},

    {name: 'Lightning',
    id: 25,
    image: lightning},
  
    {name: 'Fisherman',
    id: 26,
    image: fisherman},

    {name: 'Sparkle',
    id: 27,
    image: sparkle},

    {name: 'Romi',
    id: 28,
    image: romi},
    
]

export { newShoes }