import arcadia from '../images/womens/casual/arcadia_dark_brown.jpg'
import bellesa from '../images/womens/casual/bellesa_green.jpg'
import bend from '../images/womens/casual/bend_black.jpg'
import benji from '../images/womens/casual/benji.jpg'
import jaycina from '../images/womens/casual/jaycina_cognac.jpg'
import jewel from '../images/womens/casual/jewel_purple.jpg'
import orleans from '../images/womens/casual/orleans_taupe.jpg'
import paola from '../images/womens/casual/paola_pewter.webp'
import sandy from '../images/womens/casual/sandy_dark_red.jpg'
import shelby from '../images/womens/casual/shelby_luggage.jpg'
import sonoma from '../images/womens/casual/sonoma_royal_blue.jpg'
import travelWalker from '../images/womens/casual/travel_walker_evo_blue.jpg'
import serra from '../images/womens/casual/serra.webp'
import albany from '../images/womens/casual/albany.jpg'
import faulkner from '../images/womens/casual/wc_Faulkner.jpg'
import limoges from '../images/womens/casual/limoges.jpg'
import sofia from '../images/womens/casual/softwalk_sofia.jpg'
import gowalk from '../images/womens/casual/skechers_gowalk_lite.jpg'
import dawson from '../images/womens/casual/trotter_dawson.jpg'
import evo from '../images/womens/casual/propet_evo.webp'

const womensCasual = [
    {name: 'Travel Walker Evo MJ',
    id: 0,
    image: travelWalker},

    {name: 'Arcadia',
    id: 1,
    image: arcadia},

    {name: 'Flexus Bellasa',
    id: 2,
    image: bellesa},

    {name: 'Bend',
    id: 3,
    image: bend},

    {name: 'Shelby',
    id: 4,
    image: shelby},

    {name: 'Benji',
    id: 5,
    image: benji},

    {name: 'Jaycina',
    id: 6,
    image: jaycina},

    {name: 'Sonoma',
    id: 7,
    image: sonoma},

    {name: 'Jewel XW',
    id: 8,
    image: jewel},
    
    {name: 'Orleans',
    id: 9,
    image: orleans},
    
    {name: 'Paola',
    id: 10,
    image: paola},
    
    {name: 'Sandy',
    id: 11,
    image: sandy},
    
    {name: 'Albany',
    id: 12,
    image: albany},

    {name: 'Serra',
    id: 13,
    image: serra},

    {name: 'Limoges',
    id: 14,
    image: limoges},

    {name: 'Faulkner',
    id: 15,
    image: faulkner},

    {name: 'GOwalk Lite',
    id: 16,
    image: gowalk},

    {name: 'Sofia',
    id: 17,
    image: sofia},
    
    {name: 'Dawson',
    id: 18,
    image: dawson},

    {name: 'Travelwalker EVO MJ',
    id: 19,
    image: evo},
]
export {womensCasual}