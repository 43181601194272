import addiction from '../images/mens/athletic/brooks_addiction.jpg'
import beast from '../images/mens/athletic/brooks_beast.jpg'
import nb880 from '../images/mens/athletic/nb_880.jpg'
import m840 from '../images/mens/athletic/nb_m840.jpg'
import m928 from '../images/mens/athletic/nb_m928.jpg'
import mmor from '../images/mens/athletic/nb_mmor.jpg'
import mthim from '../images/mens/athletic/nb_mthim.jpg'
import knit from '../images/mens/athletic/propet_knit.jpg'
import stability from '../images/mens/athletic/propet_stability.jpg'
import sumner from '../images/mens/athletic/skechers_archfit_sumner.jpg'
import ghost from '../images/mens/athletic/brooks_ghost.jpg'
import cloud from '../images/mens/athletic/dunham_cloud.jpg'
import golf from '../images/mens/athletic/golf.jpg'
import boswell from '../images/mens/athletic/skechers_boswell.jpg'

const mensAthletic = [
    {name: 'M840',
    id: 0,
    image: m840},

    {name: 'Cloud Plus Lace-Up ',
    id: 1,
    image: cloud},

    {name: 'Fresh Foam X Hierro Mid',
    id: 2,
    image: mthim},

    {name: 'Ghost 14',
    id: 3,
    image: ghost},

    {name: "Arch Fit D'Lux Sumner",
    id: 4,
    image: sumner},

    {name: 'Fresh Foam X More v4',
    id: 5,
    image: mmor},

    {name: 'Stability Walker',
    id: 6,
    image: stability},

    {name: 'Beast',
    id: 7,
    image: beast},

    {name: 'M928HB3',
    id: 8,
    image: m928},

    {name: 'Tour Knit',
    id: 9,
    image: knit},

    {name: 'M880',
    id: 10,
    image: nb880},

    {name: 'Addiction GTS 15',
    id: 11,
    image: addiction},

    {name: "Go Golf Max",
    id: 12,
    image: golf},

    {name: 'Respected - Boswell',
    id: 13,
    image: boswell},
]
export { mensAthletic }