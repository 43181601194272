
export default function MapMarker() {

    const mapStyle = {
        border: 'none',
        borderRadius: '15px',
        width: '23rem',
        height: '23rem'
    }
    
    return (
        <div className='mapContainer'>
        <div></div>
            {/* <img className='storePic' src={mapPic} alt='map to store'></img> */}
            <iframe
                title='map'
                style= {mapStyle}
                referrerPolicy="no-referrer-when-downgrade"
                src= {`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_API_KEY}&q=Otherwides+Shoes,Tempe+AZ`}
                allowFullScreen
                loading="lazy">
            </iframe>
        </div>
    )
}
