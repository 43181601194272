import service from '../images/mens/dress/dunham_8000_service.jpg'
import captain from '../images/mens/dress/dunham_captain_brown.jpg'
import glastonbury from '../images/mens/dress/dunham_glastonbury.jpg'
import johnsbury from '../images/mens/dress/dunham_st_johnsbury.jpg'
import midboot from '../images/mens/dress/dunham-8000-mid-boot.jpg'
import midtownCap from '../images/mens/dress/florsheim_midtown_cap.jpg'
import midtown from '../images/mens/dress/florsheim_midtown_plain.jpg'
import riva from '../images/mens/dress/florshiem_Riva.jpg'
import finn from '../images/mens/dress/propet_finn.jpg'
import viator from '../images/mens/dress/propet_viator_strap.jpg'
import leader from '../images/mens/dress/rockport_style_leader.webp'
import parson from '../images/mens/dress/skechers_parson.jpg'
import norwalk from '../images/mens/dress/norwalk.jpg'
import holmgren from '../images/mens/dress/skechers_holmgren.jpg'
import canvas from '../images/mens/dress/florsheim_canvas.jpg'

const mensDress = [
    {name: '8000 Service',
    id: 0,
    image: service},

    {name: 'Midtown Cap',
    id: 1,
    image: midtownCap},

    {name: 'Glastonbury',
    id: 2,
    image: glastonbury},

    {name: 'Riva',
    id: 3,
    image: riva},

    {name: 'Finn',
    id: 4,
    image: finn},

    {name: '8000 Mid Boot',
    id: 5,
    image: midboot},

    {name: 'Parson-Ralven',
    id: 6,
    image: parson},

    {name: 'St. Johnsbury',
    id: 7,
    image: johnsbury},

    {name: 'Midtown Plain',
    id: 8,
    image: midtown},

    {name: 'Style Leader',
    id: 9,
    image: leader},

    {name: 'Viator Strap',
    id: 10,
    image: viator},

    {name: 'Captain',
    id: 11,
    image: captain},

    {name: 'Respected - Holmgren',
    id: 12,
    image: holmgren},

    {name: 'Crossover Canvas',
    id: 13,
    image: canvas},

    {name: "Norwalk",
    id: 14,
    image: norwalk},


]
export { mensDress }