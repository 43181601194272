import lydia from '../images/womens/dress/walkingCradles_lydia.jpg'
import joy from '../images/womens/dress/walkingCradles_joy.jpg'
import hester from '../images/womens/dress/walkingCradles_hester.jpg'
import halo from '../images/womens/dress/walkingCradles_halo.jpg'
import kimiko from '../images/womens/dress/bellavita_kimiko.webp'
import eve from '../images/womens/dress/davidtate_eve.webp'
import rave from '../images/womens/dress/davidtate_rave.jpg'
import moonlight from '../images/womens/dress/easystreet_moonlight.webp'
import ryalee from '../images/womens/dress/easyStreet_ryalee.jpg'
import daria from '../images/womens/dress/trotters_daria.jpg'
import dea from '../images/womens/dress/trotters_dea.jpg'
import sugar from '../images/womens/dress/trotters_sugar.jpg'
import porsha from '../images/womens/dress/wc_porsha.jpg'
import fadia from '../images/womens/dress/fadia.webp'
import navy from '../images/womens/dress/dea_floral.webp'
import osaka from '../images/womens/dress/revere_osaka.jpg'
import omni from '../images/womens/dress/david_tate_omni.jpg'
import tess from '../images/womens/dress/wc_tess.jpg'
import demi from '../images/womens/dress/trotters_demi.jpg'

const womensDress = [

    {name: 'Lydia',
    id: 0,
    image: lydia},

    {name: 'Woven',
    id: 1,
    image: dea},

    {name: 'Kimiko',
    id: 2,
    image: kimiko},

    {name: 'Eve',
    id: 3,
    image: eve},

    {name: 'Halo',
    id: 4,
    image: halo},

    {name: 'Daria',
    id: 5,
    image: daria},

    {name: 'Ryalee',
    id: 6,
    image: ryalee},

    {name: 'Rave',
    id: 7,
    image: rave},

    {name: 'Joy',
    id: 8,
    image: joy},

    {name: 'Moonlight',
    id: 9,
    image: moonlight},

    {name: 'Sugar',
    id: 10,
    image: sugar},

    {name: 'Hester',
    id: 11,
    image: hester},
    
    {name: 'Porsha',
    id: 12,
    image: porsha},

    {name: 'Fadia',
    id: 13,
    image: fadia},

    {name: 'Dea Floral',
    id: 14,
    image: navy},

    {name: 'Omni',
    id: 15,
    image: omni},

    {name: 'Osaka',
    id: 16,
    image: osaka},

    {name: 'Demi',
    id: 17,
    image: demi},

    {name: "Tess",
    id: 18,
    image: tess},

]

export { womensDress }