import '../styles/homepageStyle.css'
import { Link } from 'react-router-dom'
import front_counter from '../images/storepics/front_counter.jpg'
import athletic_display from '../images/storepics/athletic_display.jpg'
import panorama from '../images/storepics/panoramic.jpg'
import AnimateOnScroll from './animate'
import SignUpForm from './signup'
import AboutUs from './aboutUs'
import Carousel from './carousel/brandcarousel'
import MapMarker from './createMap'
import { useState } from 'react'

export default function Homepage () {

    const storeImages = [
        {name: 'Counter',
        image: front_counter},
        {name: 'Athletic',
        image: athletic_display},
        {name: 'Panorama',
        image: panorama},
    ]

    const [modalOpen, setModalOpen] = useState(false)
    const [activeImage, setActiveImage] = useState('')

    function openModal(image) {
        setActiveImage(image)
        setModalOpen(true)
    }

    function closeModal() {
        setModalOpen(false)
    }

    // <div className='seasonalBanner'>
    //             <div className='seasonalContent'>
    //                 <div className='seasonalTitle'>We will be closed February 19th for Presidents' Day 
    //                 </div> 
    //                 {/* <div className='seasonalText'>No matter what holiday you celebrate,<br></br></div> */}
    //             </div>
    //         </div>

    // <div className='seasonalBanner'>
    //              <div className='seasonalContent'>
    //                 <div className='seasonalTitle'>Spring Sale! 20% off your entire purchase!</div>
    //                 <div className='seasonalTitle'>Sale begins Friday April 12th through Saturday April 20th!</div> 
    //                  <div className='seasonalText'>Includes all in-stock shoes....even clearance!<br></br>Lots of new spring & summer shoes have arrived - shop soon for best selection.</div>
    //              </div>
    //          </div>

    return (
        <div className='homepage'>
            <div className='seasonalBanner'>
                <div className='seasonalContent'>
                    <div className='seasonalTitle'>We will close early on September 2nd at 3.<br></br> Happy Labor Day!
                    </div> 
                    {/* <div className='seasonalText'>No matter what holiday you celebrate,<br></br></div> */}
                </div>
            </div>

            <div className='firstSection'>
                <AnimateOnScroll 
                    children={<img className='shopImage' src= {storeImages[1].image} alt='store display' onClick={() => openModal(storeImages[1])}/>}
                    reappear={true} 
                    threshold={.3}
                    right={false}
                />
                <AnimateOnScroll
                    children={<div className='descriptionContainer'>
                                <h2>Welcome to Otherwides Shoes</h2>
                                <h3>The widest selection of shoes in the Valley</h3>
                                <div className='description'>
                                We are a local women-owned small business in Tempe, AZ. For the past 20 years we have provided our customers with a wide range of quality footwear
                                that fits. From casual sneakers to formal dress shoes, we have a huge variety of styles to choose from. We want to be <span className='line'>your</span> wide shoe store
                                </div>
                            </div>}
                    reappear={true}
                    threshold={.1}
                    right={true}
                />
            </div>
            <div className='homepageLinkContainer'>
            <Link
                className='homepageLink'
                to='/womens'>For Women
            </Link>
            <Link
                className='homepageLink'
                to='/newArrivals'>New Arrivals
            </Link>
            <Link
                className='homepageLink'
                to='/mens'>For<br></br> Men
            </Link>
            </div>
            <div className='secondSection'>
                <AnimateOnScroll
                    children={
                        <div className='services'>
                        <h3>Sizes:</h3>
                        <p>Women: 6 - 13 W, WW, XXW</p>
                        <p>Men: 8 - 18 3E - 6E</p>
                        <h3>Services:</h3>
                            <ul>
                            <li>Experienced staff capable of foot measurement</li>
                            <li>Phone orders accepted</li>
                            <li>Seasonal as well as core styles</li>
                            <li>Year-round clearance room</li>
                        </ul>
                        </div>}
                    reappear={true}
                    threshold={.2}
                    right={false}
                />
                <AnimateOnScroll
                    children={<img className='shopImage' src= {storeImages[0].image} alt='second store display' onClick={() => openModal(storeImages[0])} />}
                    reappear={true}
                    threshold={.3}
                    right={true}
                />
            </div>
            <div className='thirdSection'>
            <AnimateOnScroll 
                children={<AboutUs />}
                reappear={true}
                threshold={.3}
                right={true}
            />
            </div>
            <Carousel></Carousel>
            <AnimateOnScroll 
                children={<SignUpForm />}
                reappear={true}
                threshold={.3}
                right={true}
            />
            <AnimateOnScroll 
                children={<img className='shopImage' src= {storeImages[2].image} alt='store display' onClick={() => openModal(storeImages[2])} />}
                reappear={true} 
                threshold={.3}
                right={false}
            />
            <AnimateOnScroll 
                children={<MapMarker />}
                reappear={true}
                threshold={.3}
                right={true}
            />

            <div className={modalOpen ? 'modalOpen' : 'modalClosed'}>
                <span className='closeIcon' onClick={closeModal}>&times;</span>
                <div className='modalContent'>
                    <img className='modalPic' src= {activeImage.image} alt={activeImage.name}></img>
                </div>
            </div>
        
        </div>
    )
}